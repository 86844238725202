<template>
  <div>
    <el-divider content-position="left">订单基础信息</el-divider><br>
      <el-descriptions
      class="margin-top"
      title=""
      :column="3"
      :size="medium"
      border
      >
        <el-descriptions-item >
          <template #label>
            <el-icon></el-icon>
            标签供应商
          </template>
          {{orderInfo.supplier.name}}
        </el-descriptions-item>
        <el-descriptions-item >
          <template #label>
            <el-icon></el-icon>
            防伪码标签（套）
          </template>
          {{orderInfo.securityNumber}}
        </el-descriptions-item>
        <el-descriptions-item >
          <template #label>
            <el-icon></el-icon>
            条形码标签总数（枚）
          </template>
          {{orderInfo.barcodeNumber}}
        </el-descriptions-item>
        <el-descriptions-item v-if="orderInfo.status.name !='TO_BE_CONFIRMED'">
          <template #label>
            <el-icon></el-icon>
            采购订单状态
          </template>
          <el-steps :active="step" align-center finish-status="success" v-if="orderInfo.status.name !='TERMINATION'">
            <el-step title="已下单" :description="orderInfo.orderTime"></el-step>
            <el-step title="生产中" :description="receiveTime"></el-step>
            <el-step title="完成并发货" :description="shippedTime"></el-step>
            <el-step title="已收货" :description="receiptTime"></el-step>
          </el-steps>
          <span v-if="orderInfo.status.name ==='TERMINATION'">已取消</span>
          
        </el-descriptions-item>
    </el-descriptions><br>
    <!-- {{orderInfo.orderStatus.type}} -->
    <el-divider content-position="left">标签详情</el-divider><br>
    <el-table :data="labelDetails" style="width: 100%;" border id="labelTable" max-height="450px">
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column width="80px" label="标签状态" align="center">
          <template #default="scope">
            <el-tag :type="showFilter(scope.row)">{{scope.row.labelState?scope.row.labelState.type:''}}</el-tag>
          </template>
      </el-table-column>
      <el-table-column prop="order.orderBatch" label="订单批次" min-width="100" align="center" />
      <el-table-column width="80" label="表单拆分" align="center">
          <template #default="scope">
            <el-tag type="success">{{scope.row.split}}</el-tag>
          </template>
      </el-table-column>
      <el-table-column prop="labelApply.creatorName" label="申请人" width="100" align="center"/>
      <el-table-column prop="model.materialCode" label="物料代码" width="120" align="center"/>
      <el-table-column prop="model.name" label="型号" min-width="100" align="center"/>
      <el-table-column prop="model.unit" label="单位" width="80" align="center"/>
      <el-table-column prop="model.type.title" label="类型" width="80" align="center"/>
      <el-table-column prop="manufacturer.name" label="制造商" min-width="150" align="center"/>
      <el-table-column prop="manufacturer.code" label="制造商代码" width="70" align="center"/>
      <el-table-column prop="model.page" label="联数" width="50" align="center"/>
      <el-table-column prop="labelCount" label="防伪码标签（套）" width="80" align="center"/>
      <el-table-column prop="barcodeNumber" label="条形码标签（枚）" width="80" align="center">
        <template #default="scope">
            <span>{{scope.row.barcodeNumber?scope.row.barcodeNumber:0}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="startLabel" label="起始标签" width="240" align="center"/>
      <el-table-column prop="endLabel" label="结束标签" width="240" align="center"/>
      
      <el-table-column prop="orderRemark" label="下单备注" width="150" align="center" />
      <el-table-column align="center" label="操作" width="120px" fixed="right" v-if="this.orderInfo.orderStatus.name !='RECEIPT' && this.source==='myBills'">
          <template  #default="scope">
            <el-button type="danger" size="mini" @click="handleCancelOrder(scope.row)" 
              v-if="scope.row.labelState && scope.row.labelState.name==='IN_EFFECT'" 
              v-waves>取消下单</el-button>
          </template>
        </el-table-column>
    </el-table>
  </div>
</template>

<script>
import orderInfoApi from '@/apis/order/orderInfoTemp'
export default {
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      source: undefined,
      step: 1,
      orderId: this.id,
      orderInfo: {
        supplier: {name: undefined},
        securityNumber: undefined,
        barcodeNumber: undefined,
        orderStatus: {
          type: undefined,
          name: undefined
        },
        status: {
          type: undefined,
          name: undefined
        },
        orderTime: undefined
      },
      labelDetails: [],
      histories: [],
      receiveTime: '',
      shippedTime: '',
      receiptTime: ''
    }
  },
  created() {
    this.source = this.$route.query.source
    this.getOrderInfoById()
    this.getLabelInfoByOrderId()
  },
  mounted(){
  },
  methods:{
    showFilter(row){
      if(row.labelState && row.labelState.name === 'IN_EFFECT'){
        return 'success'
      }else{
        return 'danger'
      }
    },
    handleCancelOrder(row){
      this.$confirm('取消后同一厂家、同一申请类型、同一标签申请下所有标签都将取消，且无法恢复！确定取消吗？', '警告', {
        type: 'warning'
      }).then(() => {
        orderInfoApi.cancelOrderLabel(row.id).then(resp=>{
          if(resp.data){
            this.$message.success('标签已取消')
            this.getOrderInfoById()
            this.getLabelInfoByOrderId()
          }
        })
      })
    },
    getOrderInfoById(){
      if(this.orderId){
        orderInfoApi.getOrderInfoById(this.orderId).then(resp=>{
          if(resp.data){
            this.orderInfo = resp.data
            if(resp.data.status.name!='TO_BE_CONFIRMED' && resp.data.status.name!='TERMINATION'){
              orderInfoApi.getHistories({instanceId: resp.data.processInstanceId}).then(re=>{
                this.histories = re.data
                if(resp.data.orderStatus.name === 'PLACE_ORDER'){
                  this.step = 1
                }else if(resp.data.orderStatus.name === 'RECEIVE'){
                  this.step = 2
                  this.receiveTime = this.histories[0].endTime
                }else if(resp.data.orderStatus.name === 'SHIPPED'){
                  this.step = 3
                  this.receiveTime = this.histories[0].endTime
                  this.shippedTime = this.histories[1].endTime
                }else {
                  this.step = 4
                  this.receiveTime = this.histories[0].endTime
                  this.shippedTime = this.histories[1].endTime
                  this.receiptTime = this.histories[2].endTime
                }
              })
            }
          }
        })
      }
    },
    getLabelInfoByOrderId(){
      if(this.orderId){
        orderInfoApi.getLabelInfoByOrderId(this.orderId).then(resp =>{
          if(resp.data){
            this.labelDetails = resp.data.map(labelInfo=>{
              if(labelInfo.model.page > 1){
                labelInfo.barcodeNumber = labelInfo.labelCount * labelInfo.model.page
              }else{
                labelInfo.barcodeNumber = 0
              }
              return labelInfo
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>