import api from '../api'
/**
 * 暂存订单详情
 */
export class OrderInfoTempApi extends api {
    getOrderInfoById (id) {
        return this.request({
          url: `${this.servicePath}/${this.modulePath}/getOrderInfoById/${id}`,
          method: 'get'
        })
    }
    getLabelInfoByOrderId (orderId) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/getLabelInfoByOrderId/${orderId}`,
            method: 'get'
        })
    }
    getHistories(data){
        return this.request({
          url: `${this.servicePath}/${this.modulePath}/workflow/getHistories`,
          method: "post",
          data,
        })
      }
    cancelOrderLabel(id) {
      return this.request({
        url: `${this.servicePath}/${this.modulePath}/cancelOrderLabel/${id}`,
        method: 'get'
      })
    }
}

export default new OrderInfoTempApi('holdBillsOrderDetails')